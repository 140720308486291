import React from 'react'
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	//TopLine,
	Heading,
	Subtitle,
	ImgWrapper,
	Img,
} from './RecentWork.elements'
import { Container, Button, CentreHeading } from '../../globalStyles'
//import { Link } from 'react-router-dom'

export const RecentWork = ({
	primary,
	lightBg,
	imgStart,
	lightTopLine,
	lightText,
	lightTextDesc,
	buttonLabel,
	description,
	headline,
	topLine,
	img,
	alt,
	start,
	img2,
	alt2,
	description2,
	headline2,
	img3,
	alt3,
	description3,
	headline3,
	img4,
	alt4,
	description4,
	headline4,
	img5,
	alt5,
	description5,
	headline5,
	img6,
	alt6,
	description6,
	headline6,
	img7,
	alt7,
	description7,
	headline7,
	img8,
	alt8,
	description8,
	headline8,
	img9,
	alt9,
	description9,
	headline9,
	img10,
	alt10,
	description10,
	headline10,
}) => {
	return (
		<>
			<InfoSec lightBg={lightBg}>
				<Container>
					<CentreHeading>Recent Work</CentreHeading>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img} alt={alt} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
								<a
									href='https://ultimatespitroastmotors.com/'
									target='_blank'
									rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img2} alt={alt2} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline2}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
								<a href='https://emarket.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img3} alt={alt3} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline3}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
								<a href='https://alphaarietis.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img4} alt={alt4} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline4}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description4}</Subtitle>
								<a href='https://dnapainters.co.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img5} alt={alt5} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline5}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description5}</Subtitle>
								<a href='https://cookingwithmagic.co.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img6} alt={alt6} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline6}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description6}</Subtitle>
								<a
									href='https://thelettingcompany.co.nz/'
									target='_blank'
									rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>

					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img7} alt={alt7} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline7}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description7}</Subtitle>
								<a href='https://www.cosell.co.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img8} alt={alt8} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline8}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description8}</Subtitle>
								<a href='https://dronesatwork.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>

					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img9} alt={alt9} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline9}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description9}</Subtitle>
								<a
									href='https://eurotechsolutions.co.nz/'
									target='_blank'
									rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<ImgWrapper start={start}>
								<Img src={img10} alt={alt10} />
							</ImgWrapper>
							<TextWrapper>
								<Heading lightText={lightText}>{headline10}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description10}</Subtitle>
								<a href='https://ziggie.co.nz/' target='_blank' rel='noopener noreferrer'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>
				</Container>
			</InfoSec>
		</>
	)
}
