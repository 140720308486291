import React from 'react'
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	TopLine,
	Heading,
	Subtitle,
	//ImgWrapper,
	//Img,
} from './OurSteps.elements'
import { Container, CentreHeading } from '../../globalStyles'
//import { Link } from 'react-router-dom'

export const OurSteps = ({
	primary,
	lightBg,
	imgStart,
	lightTopLine,
	lightText,
	lightTextDesc,
	buttonLabel,
	description,
	headline,
	topLine,
	img,
	alt,
	start,
	description2,
	headline2,
	topLine2,
	description3,
	headline3,
	topLine3,
	description4,
	headline4,
	topLine4,
}) => {
	return (
		<>
			<InfoSec lightBg={lightBg}>
				<Container>
					<CentreHeading>Results Driven Process</CentreHeading>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<TextWrapper>
								<TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
								<Heading lightText={lightText}>{headline}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<TextWrapper>
								<TopLine lightTopLine={lightTopLine}>{topLine2}</TopLine>
								<Heading lightText={lightText}>{headline2}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<TextWrapper>
								<TopLine lightTopLine={lightTopLine}>{topLine3}</TopLine>
								<Heading lightText={lightText}>{headline3}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<TextWrapper>
								<TopLine lightTopLine={lightTopLine}>{topLine4}</TopLine>
								<Heading lightText={lightText}>{headline4}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description4}</Subtitle>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>
				</Container>
			</InfoSec>
		</>
	)
}

// link and button
// <Link to='/sign-up'>
// <Button big fontBig primary={primary}>
// {buttonLabel}
// </Button>
// </Link>
