import React from 'react'
import {
	InfoSection,
	ServiceTiles,
	Hero,
	OurSteps,
	RecentWork,
	Testimonials,
} from '../../components'
import { hero, intro, oursteps, recentwork, testimonials } from './Data'

export const Home = () => {
	return (
		<>
			<Hero {...hero} />
			<InfoSection {...intro} />
			<ServiceTiles />
			<OurSteps {...oursteps} />
			<RecentWork {...recentwork} />
			<Testimonials {...testimonials} />
		</>
	)
}
