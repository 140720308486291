import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ServiceSection = styled.div`
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
`

export const ServiceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	@media screen and (max-width: 960px) {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const ServiceHeading = styled.h1`
	color: #2b2b2b;
	font-size: 48px;
	margin-bottom: 50px;
	justify-content: center;
	align-items: center;
	text-align: center;
`

export const ServiceContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 960px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
`

export const ServiceCard = styled(Link)`
	background: #fff;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
	width: 280px;
	height: 500px;
	text-decoration: none;
	border-radius: 0px;
	transition: all 0.3s ease-out;
	&:nth-child(2) {
		margin: 24px;
	}
	&:hover {
		transform: scale(1.06);
		transition: all 0.3s ease-out;
		color: #fff;
	}
	@media screen and (max-width: 960px) {
		width: 90%;
		&:hover {
			transform: none;
		}
	}
`

export const ServiceCardInfo = styled.div`
	display: flex;
	flex-direction: column;
	height: 500px;
	padding: 24px;
	align-items: center;
	color: #2b2b2b;
`

export const ServiceCardIcon = styled.div`
	margin: 24px 0;
`

export const ServiceCardPlan = styled.h3`
	margin-bottom: 15px;
	font-size: 24px;
`

export const ServiceCardCost = styled.h4`
	font-size: 40px;
`

export const ServiceCardLength = styled.p`
	font-size: 14px;
	margin-bottom: 15px;
`

export const ServiceCardFeatures = styled.ul`
	margin: 16px 0 32px;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: dimgray;
`

export const ServiceCardFeature = styled.li`
	margin-bottom: 10px;
`
