import React from 'react'
import emailjs, { init } from 'emailjs-com'
//import { useForm } from 'react-hook-form'
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	TopLine,
	Heading,
	Subtitle,
	Form,
	Input,
	Label,
	TextArea,
	//ImgWrapper,
	//Img,
} from './ContactUs.elements'
import { Container, Button } from '../../globalStyles'
//import { Link } from 'react-router-dom'

init('user_LbQV1F9ZdCyrAaPfiiv7X')

export const ContactUs = ({
	primary,
	lightBg,
	imgStart,
	lightTopLine,
	lightText,
	lightTextDesc,
	buttonLabel,
	description,
	headline,
	topLine,
	img,
	alt,
	start,
}) => {
	const sendEmail = (e) => {
		e.preventDefault()

		emailjs
			.sendForm('grow-my-digital-gmail', 'template_lkepqzi', e.target, 'user_LbQV1F9ZdCyrAaPfiiv7X')
			.then(
				(result) => {
					console.log(result.text)
				},
				(error) => {
					console.log(error.text)
				}
			)
		e.target.reset()
	}

	return (
		<>
			<InfoSec lightBg={lightBg}>
				<Container>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<TextWrapper>
								<TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
								<Heading lightText={lightText}>{headline}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
								<a href='tel:+6421922775'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</a>
							</TextWrapper>
						</InfoColumn>
						<InfoColumn>
							<Form onSubmit={sendEmail}>
								<Label>Your Name</Label>
								<Input type='text' name='name' required />
								<Label>Your Email</Label>
								<Input type='email' name='email' required />
								<Label>Your Website</Label>
								<Input type='text' name='website' />
								<Label>Your Message</Label>
								<TextArea name='message' required />
								<div>
									<Button type='submit' big fontBig primary={primary}>
										SEND {'>>'}
									</Button>
								</div>
							</Form>
						</InfoColumn>
					</InfoRow>
				</Container>
			</InfoSec>
		</>
	)
}
