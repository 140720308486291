import React, { useState, useEffect } from 'react'
import {
	Nav,
	NavbarContainer,
	//NavIcon,
	NavLogo,
	MobileIcon,
	NavItem,
	NavLinks,
	NavMenu,
	NavItemBtn,
	NavBtnLink,
} from './Navbar.elements'
import { FaTimes, FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { Button } from '../../globalStyles'

export const Navbar = () => {
	const [click, setClick] = useState(false)
	const [buttonFontBig, setButtonFontBig] = useState(true)

	const handleClick = () => setClick(!click)

	const mobileMenuClose = () => setClick(false)

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButtonFontBig(false)
		} else {
			setButtonFontBig(true)
		}
	}
	// very cool trick to check what is happening in the WINDOW!
	useEffect(() => {
		showButton()
	}, [])

	window.addEventListener('resize', showButton)

	return (
		<>
			<IconContext.Provider value={{ color: '#ff0800' }}>
				<Nav>
					<NavbarContainer>
						<NavLogo to='/' onClick={mobileMenuClose}>
							<h1>
								GROW MY <br /> DIGITAL {'>>'}
							</h1>
						</NavLogo>
						<MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
						<NavMenu onClick={handleClick} click={click}>
							<NavItem>
								<NavLinks to='/'>Home</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks to='/services'>Services</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks to='/contact'>Contact</NavLinks>
							</NavItem>
							<NavItemBtn>
								{buttonFontBig ? (
									<NavBtnLink href='tel:+6421922775'>
										<Button primary>CALL NOW {'>>'}</Button>
									</NavBtnLink>
								) : (
									<NavBtnLink href='tel:+6421922775'>
										<Button primary fontBig>
											CALL NOW
										</Button>
									</NavBtnLink>
								)}
							</NavItemBtn>
						</NavMenu>
					</NavbarContainer>
				</Nav>
			</IconContext.Provider>
		</>
	)
}
