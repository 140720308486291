import styled from 'styled-components'

export const InfoSec = styled.div`
	color: #fff;
	padding: 50px 0;
	background: ${({ lightBg }) => (lightBg ? '#fff' : '#101522')};
`

export const InfoRow = styled.div`
	display: flex;
	margin: 0 -15px -15px -15px;
	//align-items: center;
	flex-wrap: wrap;
	flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`

export const InfoColumn = styled.div`
	margin-bottom: 15px;
	padding-right: 15px;
	padding-left: 15px;
	flex: 1;
	max-width: 50%;
	flex-basis: 50%;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		justify-content: center;
	}
`

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 50px;
	padding-bottom: 60px;
	@media screen and (max-width: 768px) {
		padding-bottom: 65px;
	}
`

export const TopLine = styled.div`
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 1.4px;
	margin-bottom: 16px;
	color: ${({ lightTopLine }) => (lightTopLine ? '#ff0800' : '#ff0800')};
`

export const Heading = styled.h2`
	margin-bottom: 24px;
	font-size: 30px;
	line-height: 1.1;
	color: ${({ lightText }) => (lightText ? '#fff' : '#2b2b2b')};
`

export const Subtitle = styled.p`
	max-width: 440px;
	margin-bottom: 35px;
	font-size: 18px;
	line-height: 32px;
	color: ${({ lightTextDesc }) => (lightTextDesc ? '#fff' : 'dimgrey')};
`

export const ImgWrapper = styled.div`
	max-width: 555px;
	display: block;
	justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`

export const Img = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	max-height: 500px;
`
