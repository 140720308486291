export const hero = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: true,
	lightText: true,
	lightTextDesc: true,
	buttonLabel: `GET FREE AUDIT >>`,
	description:
		'Are you leaving money on the table because of poor website performance? Get a free custom audit report with simple steps on how you can improve your website today.',
	headline: 'Risk-Free Website Audit',
	topLine: 'Great Results',
	//img: require('../../images/shop.svg'),
	alt: 'third section image',
	start: '',
}

export const intro = {
	lightBg: true,
	primary: false,
	imgStart: false,
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'I WANT IN >>',
	description:
		'Imagine that you want to grow an apple orchard. You prepare the site, plant the seedlings, take care of the trees and then pick the fruit. If done right, you will have many seasons of apples to enjoy! At Grow My Digital we use the same approach to grow your business revenue. We build your website, grow its search engine rank and promote it using paid advertising to get you more sales. Get in touch today to discuss your unique business goals.',
	headline: 'Apples & Sales',
	//topLine: 'Media Library',
	img: require('../../images/laptopapples.jpg'),
	alt: 'second section image',
	start: '',
}

export const oursteps = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Results',
	description:
		'Together we do a deep dive to discover your business goals and challenges. Usually, this call takes 30 to 45 minutes and lays the foundations for our work. From here, we will take care of the rest.',
	headline: 'Discover',
	topLine: 'Step 1',
	//img: require('../../images/shop.svg'),
	//alt: 'third section image',
	start: '',
	description2:
		'Now that we know where you want to be and what is holding you back, we start building your online business tool - your website. It takes around 2 to 4 weeks to create a quality website.',
	headline2: 'Build',
	topLine2: 'Step 2',
	description3:
		'Once your website is up and running, you need your potential customers to go there. We implement an ongoing search engine optimisation strategy while driving immediate traffic using paid ads.',
	headline3: 'Promote',
	topLine3: 'Step 3',
	description4:
		'To get you the best results (whether it is more leads, better customers or more shop visits) we track and analyse what is working and what is not. We then tweak the campaigns for top performance.',
	headline4: 'Optimise',
	topLine4: 'Step 4',
}

export const recentwork = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'VISIT >>',
	description:
		'A landing page with integrated online shop for a very unique physical product - spit roast motors. Take a look at the content-rich website Grow My Digital created for enhanced SEO experience.',
	headline: 'Ultimate Spit Roast Motors',
	topLine: '',
	img: require('../../images/ultimatespitroastmotors.jpg'),
	alt: 'Ultimate Spit Roast Motors by Grow My Digital',
	description2:
		'New Zealand classified ads portal where people can post ads and connect with buyers and sellers. Grow My Digital took on the process of building a functional, feature-rich website.',
	headline2: 'eMarket Classifieds',
	topLine2: '',
	img2: require('../../images/emarket.jpg'),
	alt2: 'eMarket Classifieds by Grow My Digital',
	description3:
		'A one-page website with information about services and prices, plus lead generation forms. Grow My Digital have designed, built and optimised this website from the ground up.',
	headline3: 'Alpha Arietis Web Studio',
	topLine3: '',
	img3: require('../../images/alphaarietis.jpg'),
	alt3: 'Alpha Arietis by Grow My Digital',

	description4:
		'A lead generation website for a local house painting company in Auckland. Grow My Digital worked on the website design and development, along with marketing items such as business cards.',
	headline4: 'D&A Painters',
	topLine4: '',
	img4: require('../../images/dnapainterscrop.jpg'),
	alt4: 'D&A Painters by Grow My Digital',

	description5:
		'A business information website of a local kids party entertainer in Auckland. Designed to help people build trust with this company and make a decision to book their next party with them.',
	headline5: 'Cooking with Magic',
	topLine5: '',
	img5: require('../../images/cookingwithmagiccrop.jpg'),
	alt5: 'Cooking with Magic by Grow My Digital',

	description6:
		'Info and lead generation page for a modern Auckland rental company. Grow My Digital have updated the design and implemented a custom SEO strategy which is getting the client more Google traffic.',
	headline6: 'The Letting Company',
	topLine6: '',
	img6: require('../../images/thelettingcompanycrop.jpg'),
	alt6: 'The Letting Company',

	description7:
		'Grow My Digital have created and implemented a custom Social Media Marketing strategy for this Auckland commercial refregiration supplier. The aim is ramping up brand awareness within the target audience.',
	headline7: 'CoSell Refrigeration',
	topLine7: '',
	img7: require('../../images/cosell.jpg'),
	alt7: 'CoSell Refrigeration',

	description8:
		'Business sales page for a leading edge drone imagery company in Christchurch. Grow My Digital have built and deployed their new website, complete with professional emails and  hosting package.',
	headline8: 'Drones At Work',
	topLine8: '',
	img8: require('../../images/dronesatwork.jpg'),
	alt8: 'Drones At Work',

	description9:
		'EuroTech Solutions is a major player in Auckland commercial interiors market. Grow My Digital were approached to rebrand their digital look. They are now enjoying a fast, professional website that resonates with their brand identity.',
	headline9: 'EuroTech Solutions',
	topLine9: '',
	img9: require('../../images/eurotech.jpg'),
	alt9: 'EuroTech Solutions',

	description10:
		'Ziggie have asked us to revitalise their old website to bring in more life and more customers to their online presence. Grow My Digital has designed and built the new site to spec and in just three weeks to meet a tight deadline.',
	headline10: 'Ziggie Professional Cleaners',
	topLine10: '',
	img10: require('../../images/ziggie.jpg'),
	alt10: 'Ziggie Professional Cleaners',

	start: '',
}

export const testimonials = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Results',
	description:
		'I would highly recommend Grow My Digital’s services, easy to work with and good communication. Ivan took my product, the Ultimate Spit Roast Motors and basically created a new stream of income for me. He was very helpful, took amazing photos, created a new logo, set up my Facebook page and Instagram and created a online store with a payment gateway where customers can pay by Paypal or credit card. He also edited a lot of writing even organised a "How To Spit Roast" e-book. He really went out of his way and I feel super grateful for his efforts and will definitely use Grow My Digital for any future projects. 5 STAR now my product is selling in Australia and all over the place where as before i was only selling at shows and on trade me. Thank you Grow My Digital.',
	headline: '"Created a new stream of income for me"',
	topLine: '★★★★★ | Zoran, Kizo Marine Ltd',
	description2:
		'Great presentation, very well combined and built content. Knows exactly what’s required for you! Time and money saver!',
	headline2: '"Time and money saver"',
	topLine2: '★★★★★ | Alex, Belkin Services Ltd',

	description3:
		'Ivan listens and translates the request into a visual result with SEO in mind at all times. He works fast and is flexible to adjust changes along the way. Transformation of www.ziggie.co.nz happened in the record time of three weeks - and that is just a beginning of awakening our business in digital world. Thanks to Ivan the process was easy - he is excellent at narrowing the options down and pushing forward with what works. In the busy time of our business lives, Ivan solved a big puzzle, professionally. Thank you.',
	headline3: '"The process was easy"',
	topLine3: '★★★★★ | Alisa, Ziggie Group Ltd',

	description4:
		'I cant praise enough how great Grow My Digital is when it comes to service and professionalism! I was hopelessly lost on how to take my startup business online, and the team was extremely supportive and ensured that I have only the best result from website development, social media advertising, and SEO work. Ivan and the team exceeded expectations in all areas and I would highly recommend it for any businesses needing to move or grow online.',
	headline4: '"Extremely supportive"',
	topLine4: '★★★★★ | Brian, Brucio Catering',
	//img: require('../../images/shop.svg'),
	//alt: 'third section image',
	start: '',
}
