import React from 'react'
import { Navbar, Footer } from './components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { GlobalStyle } from './globalStyles'
import { Home } from './pages/HomePage/Home'
import { MyServices } from './pages/MyServices/MyServices'
import { Contact } from './pages/Contact/Contact'
//import { SignUp } from './pages/SignUp/SignUp'
import { ScrollToTop } from './components/ScrollToTop'

function App() {
	return (
		<Router>
			<GlobalStyle />
			<ScrollToTop />
			<Navbar />
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/services' exact component={MyServices} />
				<Route path='/contact' exact component={Contact} />
				<Redirect to='/' />
			</Switch>
			<Footer />
		</Router>
	)
}

export default App

// WEBSITE COLOURS
// theme red: #ff0800
// dark title text: #2b2b2b
// dark sub/paragraph text: dimgrey
// box shadow: grba(0,0,0,0.2)
