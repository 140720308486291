// this file is like a global CSS file that all pages have access to
// more info in Styled Components docs

import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	font-family: 'Open Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Poppins', sans-serif;
}
`
export const Container = styled.div`
	z-index: 1;
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 50px;
	padding-right: 50px;

	@media screen and (max-width: 991px) {
		padding-left: 50px;
		padding-right: 50px;
	}
`

export const Button = styled.button`
	font-family: 'Poppins', sans-serif;
	background: ${({ primary }) => (primary ? '#ff0800' : '#ff0800')};
	white-space: nowrap;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	color: #fff;
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-out;

	&:hover {
		transition: all 0.2s ease-out;
		color: #fff;
		background: ${({ primary }) => (primary ? '#DB0300' : '#DB0300')};
		box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
	}

	@media screen and (max-width: 960px) {
		width: 100%;
	}
`

export const CentreHeading = styled.h2`
	color: #2b2b2b;
	font-size: 48px;
	margin-bottom: 50px;
	text-align: center;
`
