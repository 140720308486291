export const herosub = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: true,
	lightText: true,
	lightTextDesc: true,
	buttonLabel: 'Get Results',
	description: `Let's make something great happen!`,
	headline: 'Get In Touch',
	topLine: 'Great Results',
	//img: require('../../images/shop.svg'),
	alt: 'third section image',
	start: '',
}

export const contact = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'CALL US >>',
	description:
		'To learn more about pricing or to request a free website audit please fill out the quick form. Prefer to talk on the phone? Call us now by clicking the button below.',
	headline: `Ready To Get More Customers?`,
	topLine: '',
	//img: require('../../images/finance.svg'),
	alt: 'first section image',
	start: '',
}
