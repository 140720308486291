import React from 'react'
import { InfoSectionNarrow, HeroSub } from '../../components'
import { herosub, webdesign, seo, ppc, analytics } from './Data'

export const MyServices = () => {
	return (
		<>
			<HeroSub {...herosub} />
			<InfoSectionNarrow {...webdesign} />
			<InfoSectionNarrow {...seo} />
			<InfoSectionNarrow {...ppc} />
			<InfoSectionNarrow {...analytics} />
		</>
	)
}
