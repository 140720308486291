import React from 'react'
import { Link } from 'react-router-dom'
import { FaLinkedin, FaFacebook } from 'react-icons/fa'
import { Button } from '../../globalStyles' //footer subscription
import {
	FooterContainer,
	FooterSubscription, //footer subscription
	FooterSubHeading, //footer subscription
	//FooterSubText, //footer subscription
	//Form, //footer subscription
	//FormInput, //footer subscription
	FooterLinksContainer,
	FooterLinksWrapper,
	FooterLinksItems,
	FooterLinkTitle,
	FooterLink,
	//SocialIcon, //icon before brand name text
	SocialIconLink,
	SocialIcons,
	//SocialLogo, //brand name text
	SocialMedia,
	SocialMediaWrapper,
	WebsiteRights,
	FooterALink,
} from './Footer.elements'

export const Footer = () => {
	return (
		<>
			<FooterContainer>
				<FooterSubscription>
					<FooterSubHeading>
						Ready to grow your business online and get more sales?
					</FooterSubHeading>
					<Link to='/contact'>
						<Button fontBig>LET'S TALK {'>>'}</Button>
					</Link>
				</FooterSubscription>
				<FooterLinksContainer>
					<FooterLinksWrapper>
						<FooterLinksItems>
							<FooterLinkTitle>About</FooterLinkTitle>
							<FooterLink to='/'>Who We Are</FooterLink>
							<FooterLink to='/'>Easy Process</FooterLink>
						</FooterLinksItems>
						<FooterLinksItems>
							<FooterLinkTitle>Services</FooterLinkTitle>
							<FooterLink to='/services'>Web Design</FooterLink>
							<FooterLink to='/services'>SEO</FooterLink>
							<FooterLink to='/services'>PPC</FooterLink>
							<FooterLink to='/services'>Analytics</FooterLink>
						</FooterLinksItems>
					</FooterLinksWrapper>
					<FooterLinksWrapper>
						<FooterLinksItems>
							<FooterLinkTitle>Contact</FooterLinkTitle>
							<FooterLink to='/contact'>Message Us</FooterLink>
							<FooterALink href='tel:+6421922775'>Call Us</FooterALink>
							<FooterALink href='mailto:ivan@growmydigital.nz'>Email Us</FooterALink>
						</FooterLinksItems>
						<FooterLinksItems>
							<FooterLinkTitle>Social</FooterLinkTitle>
							<FooterALink
								href='https://www.facebook.com/GrowMyDigitalNZ/'
								target='_blank'
								rel='noopener noreferrer'>
								Facebook
							</FooterALink>
							<FooterALink
								href='https://www.linkedin.com/company/grow-my-digital-nz'
								target='_blank'
								rel='noopener noreferrer'>
								LinkedIn
							</FooterALink>
						</FooterLinksItems>
					</FooterLinksWrapper>
				</FooterLinksContainer>
				<SocialMedia>
					<SocialMediaWrapper>
						<WebsiteRights>Grow My Digital © 2020</WebsiteRights>
						<SocialIcons>
							<SocialIconLink
								href='https://www.facebook.com/GrowMyDigitalNZ/'
								target='_blank'
								rel='noopener noreferrer'
								aria-label='Facebook'>
								<FaFacebook />
							</SocialIconLink>
							<SocialIconLink
								href='https://www.linkedin.com/company/grow-my-digital-nz'
								target='_blank'
								rel='noopener noreferrer'
								aria-label='LinkedIn'>
								<FaLinkedin />
							</SocialIconLink>
						</SocialIcons>
					</SocialMediaWrapper>
				</SocialMedia>
			</FooterContainer>
		</>
	)
}

/*
code for footer subscription

				<FooterSubscription>
					<FooterSubHeading>
						Join our exclusive membership to receive the latest news and trends
					</FooterSubHeading>
					<FooterSubText>You can unsubscribe at any time</FooterSubText>
					<Form>
						<FormInput name='email' type='email' placeholder='Your Email' />
						<Button fontBig>Subscribe</Button>
					</Form>
				</FooterSubscription>
*/
