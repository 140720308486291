export const herosub = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: true,
	lightText: true,
	lightTextDesc: true,
	buttonLabel: 'Get Results',
	description: 'Using proven strategies and the latest technology to give your business the edge.',
	headline: 'What We Do',
	topLine: 'Great Results',
	//img: require('../../images/shop.svg'),
	alt: 'third section image',
	start: '',
}

export const webdesign = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Started',
	description:
		'Your website is your digital storefront which is the first impression with potential customers. It should promote your brand, be user friendly and, most importantly, get you more customers. Here at Grow My Digital, we build your website according to the specific goals of your business. The result is an elegant, easy to use brand asset that converts visitors into customers.',
	headline: 'Website Design',
	topLine: '',
	img: require('../../images/service-design.png'),
	alt: 'first section image',
	start: '',
	id: 'webdesign',
}

export const seo = {
	lightBg: true,
	primary: true,
	imgStart: true,
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Started',
	description: `Right this very moment someone might be searching for your product or service on Google. To not loose your customers to the competition, your company website needs to rank high in search engine results. Grow My Digital helps your business appear at the top of the search results page using proven SEO strategies.`,
	headline: 'Search Engine Optimisation',
	topLine: '',
	img: require('../../images/service-seo.png'),
	alt: 'first section image',
	start: '',
	id: 'seo',
}

export const ppc = {
	lightBg: true,
	primary: true,
	imgStart: '',
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Started',
	description: `This type of advertising has proven to have a great return on investment. You only pay when a person clicks on the ad and visits your website. Grow My Digital will create an advertising campaign, launch it and manage it for you. This is a proven way to bring visitors to your online store or lead generation page, fast. Talk to us today if you need a quick boost in sales.`,
	headline: 'Pay Per Click Ads',
	topLine: '',
	img: require('../../images/service-ppc.png'),
	alt: 'first section image',
	start: '',
	id: 'ppc',
}

export const analytics = {
	lightBg: true,
	primary: true,
	imgStart: true,
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	buttonLabel: 'Get Started',
	description: `You can't manage what you can't measure. In today's digital world we have access to powerful analytics tools. Using the latest technology we track and measure how your website is performing in relation to your goals. We then work to optimise your website so it converts more visitors into paying customers.`,
	headline: 'Analytics & Reporting',
	topLine: '',
	img: require('../../images/service-analytics.png'),
	alt: 'first section image',
	start: '',
	id: 'analytics',
}
