import styled from 'styled-components'
import { Container } from '../../globalStyles'
import { Link } from 'react-router-dom'
import { FaMagento } from 'react-icons/fa'

export const Nav = styled.nav`
	background: #fff;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`

export const NavbarContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	height: 80px;

	${Container}// gets all other values from "Container" component
`

export const NavLogo = styled(Link)`
	color: #ff0800;
	justify-self: flex-start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	display: flex;
	align-items: center;
	font-family: 'Poppins', sans-serif;
	line-height: 1.2;
`

export const NavIcon = styled(FaMagento)`
	margin-right: 0.5rem;
`

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 960px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1.8rem;
		transform: translate(-100%, 60%);
		cursor: pointer;
	}
`

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;

	@media screen and (max-width: 960px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 90vh;
		position: absolute;
		top: 80px;
		left: ${({ click }) => (click ? 0 : '-100%')};
		// Styled Components notation: if I click it I want it to show, otherwise I want it to disappear
		// more on this can be found in Styled Components docs
		opacity: 0.9;
		transition: all 0.5s ease;
		background: #eee;
	}
`
export const NavItem = styled.li`
	height: 80px;
	border-bottom: 3px solid transparent;
	transition: all 0.3s ease-out;

	&:hover {
		border-bottom: 3px solid #ff0800;
		transition: all 0.3s ease-out;
	}

	@media screen and (max-width: 960px) {
		width: 100%;

		&:hover {
			border: none;
		}
	}
`

export const NavLinks = styled(Link)`
	color: dimgray;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 2rem;
		width: 100%auto;
		display: table;

		&:hover {
			color: #4b59f7;
			transition: all 0.3s ease-out;
		}
	}
`

export const NavItemBtn = styled.li`
	@media screen and (max-width: 960px) {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%auto;
		height: 120px;
	} ;
`

export const NavBtnLink = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
`
