import React from 'react'
import { ContactUs, HeroSub } from '../../components'
import { contact, herosub } from './Data'

export const Contact = () => {
	return (
		<>
			<HeroSub {...herosub} />
			<ContactUs {...contact} />
		</>
	)
}
