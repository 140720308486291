import React from 'react'
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	//TopLine,
	Heading,
	Subtitle,
	//ImgWrapper,
	//Img,
} from './Hero.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

export const Hero = ({
	primary,
	lightBg,
	imgStart,
	lightTopLine,
	lightText,
	lightTextDesc,
	buttonLabel,
	description,
	headline,
	topLine,
	img,
	alt,
	start,
}) => {
	return (
		<>
			<InfoSec lightBg={lightBg}>
				<Container>
					<InfoRow imgStart={imgStart}>
						<InfoColumn>
							<TextWrapper>
								<Heading lightText={lightText}>{headline}</Heading>
								<Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
								<Link to='/contact'>
									<Button big fontBig primary={primary}>
										{buttonLabel}
									</Button>
								</Link>
							</TextWrapper>
						</InfoColumn>
					</InfoRow>
				</Container>
			</InfoSec>
		</>
	)
}

// topline
//<TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
