import React from 'react'
import { Button } from '../../globalStyles'
import { GiFruitTree, GiSeedling, GiSpade } from 'react-icons/gi'
import { IconContext } from 'react-icons/lib'
import {
	ServiceSection,
	ServiceWrapper,
	ServiceHeading,
	ServiceContainer,
	ServiceCard,
	ServiceCardInfo,
	ServiceCardIcon,
	ServiceCardPlan,
	ServiceCardCost,
	ServiceCardLength,
	ServiceCardFeatures,
	ServiceCardFeature,
} from './ServiceTiles.elements'

export const ServiceTiles = () => {
	return (
		<IconContext.Provider value={{ color: '#ff0800', size: 64 }}>
			<ServiceSection>
				<ServiceWrapper>
					<ServiceHeading>What We Do</ServiceHeading>
					<ServiceContainer>
						<ServiceCard to='/services'>
							<ServiceCardInfo>
								<ServiceCardIcon>
									<GiSpade />
								</ServiceCardIcon>
								<ServiceCardPlan>Website</ServiceCardPlan>
								<ServiceCardLength>starting from</ServiceCardLength>
								<ServiceCardCost>$995</ServiceCardCost>
								<ServiceCardFeatures>
									<ServiceCardFeature>Web Design</ServiceCardFeature>
									<ServiceCardFeature>Development</ServiceCardFeature>
									<ServiceCardFeature>Maintenance</ServiceCardFeature>
								</ServiceCardFeatures>
								<Button primary>MORE INFO {'>>'}</Button>
							</ServiceCardInfo>
						</ServiceCard>
						<ServiceCard to='/services'>
							<ServiceCardInfo>
								<ServiceCardIcon>
									<GiSeedling />
								</ServiceCardIcon>
								<ServiceCardPlan>SEO</ServiceCardPlan>
								<ServiceCardLength>starting from</ServiceCardLength>
								<ServiceCardCost>$495/m</ServiceCardCost>
								<ServiceCardFeatures>
									<ServiceCardFeature>Keyword Research</ServiceCardFeature>
									<ServiceCardFeature>On-Page Optimisation</ServiceCardFeature>
									<ServiceCardFeature>Off-Page Optimisation</ServiceCardFeature>
								</ServiceCardFeatures>
								<Button primary>MORE INFO {'>>'}</Button>
							</ServiceCardInfo>
						</ServiceCard>
						<ServiceCard to='/services'>
							<ServiceCardInfo>
								<ServiceCardIcon>
									<GiFruitTree />
								</ServiceCardIcon>
								<ServiceCardPlan>PPC</ServiceCardPlan>
								<ServiceCardLength>starting from</ServiceCardLength>
								<ServiceCardCost>$195/m</ServiceCardCost>
								<ServiceCardFeatures>
									<ServiceCardFeature>Digital Strategy</ServiceCardFeature>
									<ServiceCardFeature>Campaign Setup</ServiceCardFeature>
									<ServiceCardFeature>Ads Management</ServiceCardFeature>
								</ServiceCardFeatures>
								<Button primary>MORE INFO {'>>'}</Button>
							</ServiceCardInfo>
						</ServiceCard>
					</ServiceContainer>
				</ServiceWrapper>
			</ServiceSection>
		</IconContext.Provider>
	)
}
